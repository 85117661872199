import React from "react";
import HomePage from "./pages/HomePage";
import RecipePage from "./pages/RecipePage";
import RecipeEditPage from "./pages/RecipeEditPage";
import Recipes from "./context/Recipes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import RecipeCreatePage from "./pages/RecipeCreatePage";
import Header from "./components/Header";
import "@shopify/polaris/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import TagsPage from "./pages/TagsPage";

const Main = styled.div`
  flex: 1;
  height: 100vh;
  overflow: auto;
  background: #f8f7f8;
`;

const App: React.FC = () => {
  return (
    <Recipes.Provider>
      <AppProvider i18n={enTranslations}>
        <Router>
          <Main>
            <Header />
            <Switch>
              <Route
                exact={true}
                path="/recipe/new"
                component={RecipeCreatePage}
              />
              <Route exact={true} path="/" component={HomePage} />
              <Route exact={true} path="/tags" component={TagsPage} />
              <Route
                exact={true}
                path="/recipe/:recipeKey"
                component={RecipePage}
              />
              <Route
                exact={true}
                path="/recipe/:recipeKey/edit"
                component={RecipeEditPage}
              />
            </Switch>
          </Main>
        </Router>
      </AppProvider>
    </Recipes.Provider>
  );
};

export default App;
