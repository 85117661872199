import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Recipes from "../context/Recipes";
import { TextContainer, Badge } from "@shopify/polaris";
import SPKSpinner from "./SPKSpinner";
import Recipe from "../model/Recipe";
import RecipeThumbnail from "./RecipeThumbnail";

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  font-size: large;
  text-decoration: none;
  align-items: center;
  border-bottom: 1px solid #c4cdd5;
  padding-bottom: 8px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledTextContainer = styled.div`
  padding: 8px;
`;

interface RecipeListProps {
  filterBy?: string;
  filterByTag?: string;
  showTags?: boolean;
}

function filterByTerm(recipe: Recipe, term: string | undefined) {
  // no search term present, let all recipes pass
  if (!term) {
    return true;
  }
  // recipe has no title, instafail
  if (!recipe.title) return false;

  return recipe.title.match(new RegExp(term, "i"));
}

function filterByTag(recipe: Recipe, tagTerm: string | undefined) {
  // no search term present, let all recipes pass
  if (!tagTerm) {
    return true;
  }
  // recipe has no tags, instafail
  if (!recipe.tags) return false;

  return recipe.tags.match(new RegExp(tagTerm, "i"));
}

function RecipeList(props: RecipeListProps) {
  let recipeContainer = Recipes.useContainer();
  let reloadFunction = recipeContainer.reload;
  console.log(recipeContainer);
  React.useEffect(() => {
    if (recipeContainer.loadedSuccessfully) {
      return;
    }
    reloadFunction();
  }, [reloadFunction, recipeContainer.loadedSuccessfully]);

  return (
    <div>
      {recipeContainer.loading === true ? <SPKSpinner /> : null}
      {recipeContainer.recipes
        .filter((recipe) => filterByTerm(recipe, props.filterBy))
        .filter((recipe) => filterByTag(recipe, props.filterByTag))
        .map((recipe) => {
          return (
            <StyledLink to={`/recipe/${recipe.key}`} key={recipe.key}>
              <ListItem key={recipe.key}>
                <RecipeThumbnail recipeState={recipe.state} />
                <StyledTextContainer>
                  <TextContainer> {recipe.title}</TextContainer>
                  {props.showTags
                    ? recipe.tags
                      ? recipe.tags.split(" ").map((tag) => {
                          return <Badge key={tag}>{tag}</Badge>;
                        })
                      : null
                    : null}
                </StyledTextContainer>
              </ListItem>
            </StyledLink>
          );
        })}
    </div>
  );
}

export default RecipeList;
