import axios from "axios";
import isProduction from "./Environment";

var base = "";
if (isProduction()) {
  console.log("setting base to heroku");
  // base = "https://speisekammer-backend.herokuapp.com";
  base = "https://web-production-452b.up.railway.app";
} else {
  console.log("setting base to localhost");
  base = "http://localhost:3002";
}

export default class APIClient {
  static get(path: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      if (!path) {
        return reject();
      }
      console.log("success0");
      console.log("basee is " + base);
      axios.defaults.headers.common["Api-Key"] = "x338P46q9787R4i";
      axios
        .get(base + path)
        .then((response) => {
          console.log("success2");
          console.log(response);
          return resolve(response);
        })
        .catch(function (error) {
          console.log("error2");
          console.log(error);
          return reject(error);
        });
    }).catch(function (error) {
      console.log("error1");
      console.log(error);
    });
  }

  static put(path: string, data: any): Promise<any> {
    return new Promise(function (resolve, reject) {
      if (!path) {
        return reject();
      }
      console.log("success0");

      axios.defaults.headers.common["Api-Key"] = "x338P46q9787R4i";
      axios
        .put(base + path, data)
        .then((response) => {
          console.log("success2");
          console.log(response);
          return resolve(response);
        })
        .catch(function (error) {
          console.log("error2");
          console.log(error);
          return reject(error);
        });
    }).catch(function (error) {
      console.log("error1");
      console.log(error);
    });
  }

  static post(path: string, data: any): Promise<any> {
    return new Promise(function (resolve, reject) {
      if (!path) {
        return reject();
      }
      console.log("success0");
      axios.defaults.headers.common["Api-Key"] = "x338P46q9787R4i";
      axios
        .post(base + path, data)
        .then((response) => {
          console.log("success2");
          console.log(response);
          return resolve(response);
        })
        .catch(function (error) {
          console.log("error2");
          console.log(error);
          return reject(error);
        });
    }).catch(function (error) {
      console.log("error1");
      console.log(error);
    });
  }

  static delete(path: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      if (!path) {
        return reject();
      }
      console.log("success0");
      axios.defaults.headers.common["Api-Key"] = "x338P46q9787R4i";
      axios
        .delete(base + path)
        .then((response) => {
          console.log("success2");
          console.log(response);
          return resolve(response);
        })
        .catch(function (error) {
          console.log("error2");
          console.log(error);
          return reject(error);
        });
    }).catch(function (error) {
      console.log("error1");
      console.log(error);
    });
  }

  //   static patch(path: string, data: any): Promise<any> {
  //     return new Promise(function(resolve, reject) {
  //       if (
  //         !path ||
  //         !firebaseInstance.auth() ||
  //         !firebaseInstance.auth().currentUser
  //       ) {
  //         return reject();
  //       }
  //       console.log("success0");

  //       firebaseInstance
  //         .auth()!
  //         .currentUser!.getIdToken(/* forceRefresh */ true)
  //         .then(function(idToken) {
  //           console.log("success1");
  //           axios.defaults.headers.common["Authorization"] = "Bearer " + idToken;
  //           axios
  //             .patch(
  //               "https://firestore.googleapis.com/v1/projects/speisekammer-cc601/databases/(default)/documents/" +
  //                 path,
  //               data
  //             )
  //             .then(response => {
  //               console.log("success2");
  //               console.log(response);
  //               return resolve(response);
  //             })
  //             .catch(function(error) {
  //               console.log("error2");
  //               console.log(error);
  //               return reject(error);
  //             });
  //         })
  //         .catch(function(error) {
  //           console.log("error1");
  //           console.log(error);
  //         });
  //     });
  //   }
}
