import { createContainer } from "unstated-next";
import React from "react";
import Recipe from "../model/Recipe";

function useRecipes(initialState: Recipe[] = []) {
  const [recipes, setRecipes] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const [loadedSuccessfully, setLoadedSuccessfully] = React.useState(false);
  const reload = () => {
    console.log("loading recipes");
    setLoading(true);
    setLoadedSuccessfully(false);
    Recipe.fetchAllRecipes().then((recipes) => {
      setRecipes(recipes);
      setLoading(false);
      setLoadedSuccessfully(true);
      console.log("recipes loaded");
    });
  };
  return { recipes, loading, loadedSuccessfully, reload };
}

const Recipes = createContainer(useRecipes);

export default Recipes;
