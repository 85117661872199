import React from "react";
import styled from "styled-components";
import { Spinner } from "@shopify/polaris";

const Main = styled.div`
  padding: 16px 16px;
  display: flex;
  justify-content: center;
`;

function SPKSpinner() {
  return (
    <Main>
      <Spinner accessibilityLabel="Spinner" size="large" color="inkLightest" />
    </Main>
  );
}

export default SPKSpinner;
