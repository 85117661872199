import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Recipe from "../model/Recipe";
import styled from "styled-components";
import { EditMajorMonotone } from "@shopify/polaris-icons";
import {
  Button,
  Heading,
  Subheading,
  TextContainer,
  Badge,
} from "@shopify/polaris";
import { formatDistance } from "date-fns";

import APIClient from "../networking/APIClient";
import SKPSpinner from "../components/SPKSpinner";

const Main = styled.div`
  max-width: 768px;
  margin: auto;
  overflow: auto;
  background: #f8f7f8;
  padding: 8px;
`;

const HeadlineBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;

const RecipeSubElement = styled.div`
  padding: 20px 0px;
  white-space: pre-wrap;
`;

interface RecipePageProps extends RouteComponentProps<{ recipeKey: string }> {}

function RecipePage(props: RecipePageProps) {
  function handleEdit() {
    props.history.push("/recipe/" + props.match.params.recipeKey + "/edit");
  }

  const [data, setData] = useState<{
    recipe: Recipe | null;
    isFetching: boolean;
  }>({ recipe: null, isFetching: false });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({ recipe: null, isFetching: true });
        const response = await APIClient.get(
          "/recipes/" + props.match.params.recipeKey
        );
        console.log("data is " + response.data);
        setData({
          recipe: new Recipe(
            response.data.key,
            response.data.title,
            response.data.ingredients,
            response.data.steps,
            response.data.comments,
            response.data.weblink,
            response.data.imagelink,
            response.data.tags,
            response.data.created_at,
            response.data.updated_at
          ),
          isFetching: false,
        });
      } catch (e) {
        console.log(e);
        setData({ recipe: null, isFetching: false });
      }
    };
    fetchData();
  }, [props.match.params.recipeKey]);
  if (data.recipe === null) {
    return <SKPSpinner />;
  }
  return (
    <Main>
      <HeadlineBar>
        <Heading>{data.recipe.title}</Heading>
        <Button onClick={handleEdit} icon={EditMajorMonotone}></Button>
      </HeadlineBar>

      {data.recipe.ingredients ? (
        <RecipeSubElement>
          <Subheading>Zutaten</Subheading>
          <TextContainer>{data.recipe.ingredients}</TextContainer>
        </RecipeSubElement>
      ) : null}

      {data.recipe.steps ? (
        <RecipeSubElement>
          <Subheading>Schritte</Subheading>
          <TextContainer>{data.recipe.steps}</TextContainer>
        </RecipeSubElement>
      ) : null}

      {data.recipe.comments ? (
        <RecipeSubElement>
          <Subheading>Kommentare</Subheading>
          <TextContainer>{data.recipe.comments}</TextContainer>
        </RecipeSubElement>
      ) : null}

      {data.recipe.weblink ? (
        <RecipeSubElement>
          <Subheading>Web-Link</Subheading>
          <TextContainer>
            <a href={data.recipe.weblink}>{data.recipe.weblink}</a>
          </TextContainer>
        </RecipeSubElement>
      ) : null}

      {data.recipe.imagelink ? (
        <RecipeSubElement>
          <Subheading>Bilder-Link</Subheading>
          <TextContainer>
            <a href={data.recipe.imagelink}>{data.recipe.imagelink}</a>
          </TextContainer>
        </RecipeSubElement>
      ) : null}

      {data.recipe.tags ? (
        <RecipeSubElement>
          <Subheading>Tags</Subheading>
          {data.recipe.tags.split(" ").map((tag) => {
            return <Badge key={tag}>{tag}</Badge>;
          })}
        </RecipeSubElement>
      ) : null}

      <RecipeSubElement>
        <Subheading>Created At</Subheading>
        <TextContainer>
          {formatDistance(new Date(data.recipe.createdAt), new Date(), {
            addSuffix: true,
          })}
        </TextContainer>
      </RecipeSubElement>

      <RecipeSubElement>
        <Subheading>Last Updated At</Subheading>
        <TextContainer>
          {formatDistance(new Date(data.recipe.updatedAt), new Date(), {
            addSuffix: true,
          })}
        </TextContainer>
      </RecipeSubElement>
    </Main>
  );
}

export default withRouter(RecipePage);
