import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Recipe from "../model/Recipe";
import styled from "styled-components";
import APIClient from "../networking/APIClient";
import {
  Button,
  ButtonGroup,
  Heading,
  TextField,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import RecipeInputText from "../components/RecipeInputText";
import RecipeInputTextArea from "../components/RecipeInputTextArea";
import SPKSpinner from "../components/SPKSpinner";

const Main = styled.div`
  max-width: 768px;
  margin: auto;
  overflow: auto;
  background: #f8f7f8;
  padding: 8px;
`;

const ButtonBar = styled.div`
display: flex
flex-direction: row;
align-items: baseline;
justify-content: space-between;
padding: 8px 0px;
`;

interface EditedRecipe {
  key: string;
  title: string;
  ingredients: string;
  steps: string;
  comments: string;
  weblink: string;
  imagelink: string;
  tags: string;
}

const useEditForm = (saveAction: any) => {
  const [inputs, setInputs] = useState({} as EditedRecipe);

  const handleSubmit = () => {
    saveAction();
  };

  const handleInputChange = (value: string, id: string) => {
    setInputs((inputs) => ({
      ...inputs,
      [id]: value,
    }));
  };

  return {
    inputs,
    setInputs,
    handleSubmit,
    handleInputChange,
  };
};

interface RecipeEditPageProps
  extends RouteComponentProps<{ recipeKey: string }> {}

function RecipeEditPage(props: RecipeEditPageProps) {
  const [modalActive, setModalActive] = useState(false);

  function handleDeleteButtonPressed() {
    setModalActive(true);
  }

  function handleModalClose() {
    setModalActive(false);
  }

  function handleReallyDelete() {
    APIClient.delete("/recipes/" + props.match.params.recipeKey).then(
      (response) => {
        props.history.push("/");
        console.log("delete is done: ");
      }
    );
  }

  function handleCancel() {
    props.history.push("/recipe/" + props.match.params.recipeKey);
  }

  const saveAction = () => {
    APIClient.put("/recipes/" + props.match.params.recipeKey, inputs).then(
      (response) => {
        props.history.push("/recipe/" + props.match.params.recipeKey);
        console.log("save is done: " + response);
      }
    );
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } = useEditForm(
    saveAction
  );

  const [data, setData] = useState<{
    recipe: Recipe | null;
    isFetching: boolean;
  }>({ recipe: null, isFetching: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({ recipe: null, isFetching: true });
        setInputs({} as EditedRecipe);
        const response = await APIClient.get(
          "/recipes/" + props.match.params.recipeKey
        );
        console.log("data is " + response.data);
        setData({
          recipe: new Recipe(
            response.data.key,
            response.data.title,
            response.data.ingredients,
            response.data.steps,
            response.data.comments,
            response.data.weblink,
            response.data.imagelink,
            response.data.tags,
            response.data.created_at,
            response.data.updated_at
          ),
          isFetching: false,
        });
        setInputs({
          key: response.data.id,
          title: response.data.title,
          ingredients: response.data.ingredients,
          steps: response.data.steps,
          comments: response.data.comments,
          weblink: response.data.weblink,
          imagelink: response.data.imagelink,
          tags: response.data.tags,
        });
      } catch (e) {
        console.log(e);
        setData({ recipe: null, isFetching: false });
        setInputs({} as EditedRecipe);
      }
    };
    fetchData();
  }, [props.match.params.recipeKey, setInputs]);

  // inputs seems to be initialized asynchronously, thus, we also have to wait for it
  // else react is unhappy about switching from uncontrolled to controlled input forms
  if (data.recipe === null || inputs.title === undefined) {
    return <SPKSpinner />;
  }
  return (
    <Main>
      <Heading>Rezept bearbeiten</Heading>
      <form>
        <RecipeInputText>
          <TextField
            label="Titel"
            type="text"
            id="title"
            value={inputs.title}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputTextArea>
          <TextField
            label="Zutaten"
            id="ingredients"
            multiline={10}
            value={inputs.ingredients}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputTextArea>
          <TextField
            label="Schritte"
            id="steps"
            multiline={10}
            value={inputs.steps}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputTextArea>
          <TextField
            label="Kommentare"
            id="comments"
            multiline={5}
            value={inputs.comments}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputText>
          <TextField
            label="Web-Link"
            type="text"
            id="weblink"
            value={inputs.weblink}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputText>
          <TextField
            label="Bild-Link"
            type="text"
            id="imagelink"
            value={inputs.imagelink}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputText>
          <TextField
            label="Tags"
            type="text"
            id="tags"
            value={inputs.tags}
            onChange={handleInputChange}
          />
        </RecipeInputText>
      </form>
      <ButtonBar>
        <Button destructive={true} onClick={handleDeleteButtonPressed}>
          Rezept löschen
        </Button>
        <ButtonGroup>
          <Button onClick={handleCancel}>Abbrechen</Button>
          <Button primary={true} onClick={handleSubmit}>
            Speichern
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Modal
        open={modalActive}
        onClose={handleModalClose}
        title="Möchtest du dieses Rezept wirklich löschen?"
        primaryAction={{
          content: "Löschen",
          onAction: handleReallyDelete,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Main>
  );
}

export default withRouter(RecipeEditPage);
