import React from "react";
import { Link } from "react-router-dom";
import RecipeList from "../components/RecipeList";
import { Button, Heading, TextField } from "@shopify/polaris";
import styled from "styled-components";
import { MobilePlusMajorMonotone } from "@shopify/polaris-icons";

const Main = styled.div`
  max-width: 768px;
  margin: auto;
  overflow: auto;
  background: #f8f7f8;
  padding: 8px;
`;

const HeadlineBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 8px;
`;

function HomePage() {
  const [filterBy, setFilterBy] = React.useState("");

  const handleSearchStringChanged = (value: string) => {
    setFilterBy(value);
  };

  return (
    <Main>
      <HeadlineBar>
        <Heading>Alle Rezepte</Heading>
        <Link to={`/recipe/new`}>
          <Button primary={true} icon={MobilePlusMajorMonotone}></Button>
        </Link>
      </HeadlineBar>
      <TextField
        label=""
        labelHidden={true}
        type="text"
        value={filterBy}
        placeholder="Finde ein Rezept..."
        onChange={handleSearchStringChanged}
      />

      <RecipeList filterBy={filterBy} />
    </Main>
  );
}

export default HomePage;
