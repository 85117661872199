import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Heading } from "@shopify/polaris";

const Main = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  overflow: auto;
  background: white;
  padding: 8px 8px;
  justify-content: space-between;
  border-bottom: 1px solid #c4cdd5;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #ffcd02;
  }
`;

function Header() {
  return (
    <Main>
      <StyledLink to={`/`}>
        <Heading>steef.de</Heading>
      </StyledLink>

      <StyledLink to={`/tags`}>
        <Heading>TAGS</Heading>
      </StyledLink>
    </Main>
  );
}

export default Header;
