import { Thumbnail } from "@shopify/polaris";
import { RecipeState } from "../model/Recipe";
import * as React from "react";

interface RecipeThumbnailProps {
  recipeState: RecipeState;
}

function RecipeThumbnail(props: RecipeThumbnailProps) {
  const stateMapper = new Map<RecipeState, string>([
    [RecipeState.Full, "icon_r.jpg"],
    [RecipeState.Link, "icon_l.jpg"],
    [RecipeState.Empty, "icon_e.jpg"],
  ]);

  return (
    <Thumbnail
      source={stateMapper.get(props.recipeState) || ""}
      alt="R"
      size="small"
    />
  );
}

export default RecipeThumbnail;
