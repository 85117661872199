import React from "react";
import RecipeList from "../components/RecipeList";
import { Heading, TextField } from "@shopify/polaris";
import styled from "styled-components";

const Main = styled.div`
  max-width: 768px;
  margin: auto;
  overflow: auto;
  background: #f8f7f8;
  padding: 8px;
`;

const HeadlineBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 8px;
`;

function TagsPage() {
  const [filterByTag, setFilterByTag] = React.useState("");

  const handleSearchStringChanged = (value: string) => {
    setFilterByTag(value);
  };

  return (
    <Main>
      <HeadlineBar>
        <Heading>Tags</Heading>
      </HeadlineBar>
      <TextField
        label=""
        labelHidden={true}
        type="text"
        value={filterByTag}
        placeholder="Filtere nach Tags..."
        onChange={handleSearchStringChanged}
      />

      <RecipeList filterByTag={filterByTag} showTags={true} />
    </Main>
  );
}

export default TagsPage;
