// import { object } from "prop-types";
import APIClient from "../networking/APIClient";

export enum RecipeState {
  Full,
  Link,
  Empty,
}

export default class Recipe {
  key: string;
  title: string;
  ingredients: string;
  steps: string;
  comments: string;
  weblink: string;
  imagelink: string;
  tags: string | undefined;
  createdAt: string;
  updatedAt: string;

  constructor(
    key: string,
    title: string,
    ingredients: string,
    steps: string,
    comments: string,
    weblink: string,
    imagelink: string,
    tags: string | undefined,
    createdAt: string,
    updated_at: string
  ) {
    this.key = key;
    this.title = title;
    this.ingredients = ingredients;
    this.steps = steps;
    this.comments = comments;
    this.weblink = weblink;
    this.imagelink = imagelink;
    this.tags = tags;
    this.createdAt = createdAt;
    this.updatedAt = updated_at;
  }

  get state() {
    if (
      this.ingredients &&
      this.ingredients.length &&
      this.steps &&
      this.steps.length
    ) {
      return RecipeState.Full;
    } else if (this.weblink && this.weblink.length) {
      return RecipeState.Link;
    } else {
      return RecipeState.Empty;
    }
  }

  //   private static idFromName(name: string): string {
  //     // name is full url, there is no id for some obscure reason
  //     // split the url by slash and then pop off the last element
  //     // to get to the id
  //     return name.split("/").pop() || "";
  //   }

  //   static addRecipe(
  //     newTitle: string,
  //     newIngredients: string,
  //     newSteps: string
  //   ): Promise<any> {
  //     return new Promise(function(resolve, reject) {
  //       let title = { stringValue: newTitle };
  //       let steps = { stringValue: newSteps };
  //       let ingredients = { stringValue: newIngredients };
  //       let fields = { title: title, steps: steps, ingredients: ingredients };
  //       let data = { fields: fields };

  //       APIClient.post("recipes/", data).then(response => {
  //         if (!response) {
  //           return reject();
  //         }
  //         let id = Recipe.idFromName(response.data.name);
  //         return resolve(id);
  //       });
  //     });
  //   }

  //   static updateRecipe(
  //     key: string,
  //     newTitle: string,
  //     newIngredients: string,
  //     newSteps: string
  //   ): Promise<any> {
  //     return new Promise(function(resolve, reject) {
  //       let title = { stringValue: newTitle };
  //       let steps = { stringValue: newSteps };
  //       let ingredients = { stringValue: newIngredients };
  //       let fields = { title: title, steps: steps, ingredients: ingredients };
  //       let data = { fields: fields };

  //       APIClient.patch("recipes/" + key, data).then(response => {
  //         if (!response) {
  //           return reject();
  //         }
  //         return resolve();
  //       });
  //     });
  //   }

  //   static deleteRecipe(key: string): Promise<any> {
  //     return new Promise(function(resolve, reject) {
  //       if (!key || key.length === 0) {
  //         return reject();
  //       }
  //       APIClient.delete("recipes/" + key).then(response => {
  //         if (!response) {
  //           return reject();
  //         }
  //         return resolve();
  //       });
  //     });
  //   }

  //   static fetchRecipe(key: string): Promise<any> {
  //     return new Promise(function(resolve, reject) {
  //       if (!key || key.length === 0) {
  //         return reject();
  //       }
  //       APIClient.get("recipes/" + key).then(response => {
  //         let fields = response.data.fields;
  //         var recipe = new Recipe(
  //           key,
  //           fields.title.stringValue,
  //           fields.ingredients.stringValue,
  //           fields.steps.stringValue
  //         );
  //         return resolve(recipe);
  //       });
  //     });
  //   }

  static fetchAllRecipes(): Promise<any> {
    return new Promise(function (resolve, reject) {
      APIClient.get("/recipes").then((response) => {
        if (!response) {
          console.log("is the server running?");
          return reject();
        }
        let recipeArray: Recipe[] = [];
        let items = response.data;
        items.forEach((item: any) => {
          var recipe = new Recipe(
            item.id,
            item.title,
            item.ingredients,
            item.steps,
            item.comments,
            item.weblink,
            item.imagelink,
            item.tags,
            item.created_at,
            item.updated_at
          );
          recipeArray.push(recipe);
        });
        return resolve(recipeArray);
      });
    });
  }
}
