import styled from "styled-components";

const RecipeInputAreaText = styled.div`
  display: flex;
  flex-direction: column;
  // background: red;
  padding: 8px 0px;
`;

export default RecipeInputAreaText;
