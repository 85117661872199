import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import APIClient from "../networking/APIClient";
import { Button, Heading, TextField, ButtonGroup } from "@shopify/polaris";
import RecipeInputText from "../components/RecipeInputText";
import RecipeInputTextArea from "../components/RecipeInputTextArea";

const Main = styled.div`
  max-width: 768px;
  margin: auto;
  overflow: auto;
  background: #f8f7f8;
  padding: 8px;
`;

const ButtonContainer = styled.div`
display: flex
flex-direction: row;
align-items: baseline;
justify-content: right;
`;

interface EditedRecipe {
  title: string;
  ingredients: string;
  steps: string;
  comments: string;
  weblink: string;
  imagelink: string;
  tags: string;
}

const useCreateForm = (saveAction: any) => {
  const [inputs, setInputs] = useState({} as EditedRecipe);

  const handleSubmit = () => {
    saveAction();
  };

  const handleInputChange = (value: string, id: string) => {
    setInputs((inputs) => ({
      ...inputs,
      [id]: value,
    }));
  };

  return {
    inputs,
    handleSubmit,
    handleInputChange,
  };
};

interface RecipeCreatePageProps extends RouteComponentProps {}

function RecipeCreatePage(props: RecipeCreatePageProps) {
  const saveAction = () => {
    APIClient.post("/recipes", inputs).then((response) => {
      props.history.push("/recipe/" + response.data.id);
    });
  };

  function handleCancel() {
    props.history.push("/");
  }

  const { inputs, handleInputChange, handleSubmit } = useCreateForm(saveAction);

  return (
    <Main>
      <Heading>Neues Rezept</Heading>
      <form>
        <RecipeInputText>
          <TextField
            label="Titel"
            type="text"
            id="title"
            value={inputs.title}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputTextArea>
          <TextField
            label="Zutaten"
            id="ingredients"
            multiline={10}
            value={inputs.ingredients}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputTextArea>
          <TextField
            label="Schritte"
            id="steps"
            multiline={10}
            value={inputs.steps}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputTextArea>
          <TextField
            label="Kommentare"
            id="comments"
            multiline={5}
            value={inputs.comments}
            onChange={handleInputChange}
          />
        </RecipeInputTextArea>

        <RecipeInputText>
          <TextField
            label="Web-Link"
            type="text"
            id="weblink"
            value={inputs.weblink}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputText>
          <TextField
            label="Bild-Link"
            type="text"
            id="imagelink"
            value={inputs.imagelink}
            onChange={handleInputChange}
          />
        </RecipeInputText>

        <RecipeInputText>
          <TextField
            label="Tags"
            type="text"
            id="tags"
            value={inputs.tags}
            onChange={handleInputChange}
          />
        </RecipeInputText>
      </form>
      <ButtonContainer>
        <ButtonGroup>
          <Button onClick={handleCancel}>Abbrechen</Button>
          <Button primary={true} onClick={handleSubmit}>
            Speichern
          </Button>
        </ButtonGroup>
      </ButtonContainer>
    </Main>
  );
}

export default withRouter(RecipeCreatePage);
